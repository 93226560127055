import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/useAuth";
import { Button, Card, Modal, message, Form } from "antd";
import {
	EditOutlined,
	InboxOutlined,
	FileTextOutlined,
} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import "./Employee.css";

const Documents = () => {
	const [documents, setDocuments] = useState({
		profile: null,
		aadhaar: null,
		pan: null,
		offerLetter: null,
		pfdeclaration: null,
	});
	const [file, setFile] = useState(null);
	const [type, setType] = useState(null);
	const [imageEditMode, setImageEditMode] = useState(false);
	const { token, apiurl } = useAuth();
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	useEffect(() => {
		fetchDocuments();
	}, []);

	const fetchDocuments = async () => {
		try {
			const response = await fetch(`${apiurl}/handleimages/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setDocuments({
					profile: data.data.profile_image,
					aadhaar: data.data.aadhar_image,
					pan: data.data.pan_image,
					offerLetter: data.data.offer_letter,
					pfdeclaration: data.data.pf_declaration,
				});
			} else {
				const data = await response.json();
				message.error(data.error || "Error fetching documents.");
			}
		} catch (error) {
			message.error("Error fetching documents.");
		}
	};

	const updateDocument = async (type) => {
		if (!file) {
			message.warning("Please select a file to upload.");
			return;
		}

		setLoading(true);
		const formData = new FormData();
		formData.append("type", type);
		formData.append("image", file);

		try {
			const response = await fetch(`${apiurl}/handleimages/`, {
				method: "PUT",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});
			if (response.ok) {
				const data = await response.json();
				message.success(data.message || "File updated successfully!");
				setImageEditMode(false);
				setFile(null);
				fetchDocuments();
			} else {
				const data = await response.json();
				message.error(data.error || "Error updating document.");
			}
		} catch (error) {
			message.error("Error updating document.");
		} finally {
			setLoading(false);
		}
	};

	const handleFileChange = (info) => {
		const { status, originFileObj } = info.file;
		if (status === "done" || status === "uploading") {
			setFile(originFileObj);
		} else if (status === "error") {
			message.error("Error uploading file.");
		}
	};

	const customRequest = ({ file, onSuccess }) => {
		setFile(file);
		onSuccess("ok");
	};

	const renderCard = (title, docType, fileUrl) => {
		const isImage = fileUrl?.match(/\.(jpg|jpeg|png)$/i);
		return (
			<Card
				className="document-item"
				title={title}
				style={{ marginBottom: 16 }}
				extra={
					<Button
						icon={<EditOutlined />}
						onClick={() => {
							setType(docType);
							setImageEditMode(true);
						}}
					/>
				}>
				{fileUrl ? (
					isImage ? (
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`${apiurl}${fileUrl}`}>
							<img
								height={200}
								width={200}
								src={`${apiurl}${fileUrl}`}
								alt={`${title}`}
							/>
						</a>
					) : (
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`${apiurl}${fileUrl}`}>
							<center>
								<FileTextOutlined style={{ fontSize: "48px" }} />
								<p>View {title}</p>
							</center>
						</a>
					)
				) : (
					<p>No file uploaded</p>
				)}
			</Card>
		);
	};

	return (
		<div className="documents-main">
			<Card bordered={false} title="Documents">
				<div className="group">
					{renderCard("PAN Card", "pan", documents.pan)}
					{renderCard("Aadhaar Card", "aadhaar", documents.aadhaar)}
					{renderCard("Offer Letter", "offerLetter", documents.offerLetter)}
					{renderCard(
						"PF Declaration",
						"pfdeclaration",
						documents.pfdeclaration
					)}
				</div>
			</Card>

			<Modal
				open={imageEditMode}
				destroyOnClose
				title={`Upload ${type ? type.replace(/([A-Z])/g, " $1") : "File"}`}
				onCancel={() => {
					setImageEditMode(false);
					setFile(null);
				}}
				footer={[
					<Button key="cancel" onClick={() => setImageEditMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form}
					layout="vertical"
					onFinish={() => updateDocument(type)}>
					<Form.Item
						label={`Upload ${type ? type.replace(/([A-Z])/g, " $1") : "File"}`}>
						<Dragger
							className="file-upload"
							accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
							maxCount={1}
							customRequest={customRequest}
							onChange={handleFileChange}>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Click or drag file to this area to upload
							</p>
							<p className="ant-upload-hint">
								Supports image and file formats: PNG, JPG, JPEG, PDF, DOC, DOCX
							</p>
						</Dragger>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default Documents;
