import React, { useEffect, useState } from "react";
import {
	Form,
	Input,
	Button,
	Select,
	message,
	Row,
	Col,
	Card,
} from "antd";
import { useAuth } from "../utils/useAuth";
import Success from "../utils/Success";
import moment from "moment";
import Loader from "../Loader/Loader";
import { useParams } from "react-router-dom";
import HeaderComponent from "../Header/Header";
import "./Employee.css";

const { Option } = Select;
const { TextArea } = Input;

const PrejoinEmployee = () => {
	const { apiurl, token } = useAuth();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [departments, setDepartments] = useState([]);
	const [designations, setDesignations] = useState([]);
	const [allowances, setAllowances] = useState([]);
	const { role, child_id } = useParams();


	useEffect(() => {
		if (role !== "BUSINESS_OWNER" && role !== "HR_HEAD") {
			fetchDepartments();
			fetchDesignations();
		}
		fetchAllowances();
	}, [role, child_id]);

	useEffect(() => {
		const ctcValue = form.getFieldValue("ctc");
		if (ctcValue) {
			if (ctcValue < 360000) {
				const autoBasic = (ctcValue / 12) * 0.5;
				form.setFieldsValue({
					basic: autoBasic,
					basic_type: "amount",
				});
			}
		}
	}, [form]);

	const fetchAllowances = async () => {
		try {
			const response = await fetch(
				`${apiurl}/allowances/?child_id=${child_id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				setAllowances(data.data);
			} else {
				message.error("Unable to fetch allowances. Please try again.");
			}
		} catch (error) {
			message.error(`Error: ${error.message}`);
		}
	};



	const fetchDepartments = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchdepartments/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setDepartments(data.data);
			} else {
				message.error("Unable to fetch departments. Please try again.");
			}
		} catch (error) {
			message.error(`Error: ${error.message}`);
		}
	};

	const fetchDesignations = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchdesignations/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setDesignations(data.data);
			} else {
				message.error("Unable to fetch designations. Please try again.");
			}
		} catch (error) {
			message.error(`Error: ${error.message}`);
		}
	};

	

	const onFinish = async () => {
		try {
			const step2Values = form.getFieldsValue();

			const combinedValues = { ...step2Values };
			setLoading(true);

			combinedValues.dateOfBirth = moment(combinedValues.dateOfBirth).format(
				"YYYY-MM-DD"
			);
			combinedValues.dateOfJoining = moment(
				combinedValues.dateOfJoining
			).format("YYYY-MM-DD");

			const allowancesData = allowances.map((allowance) => ({
				name: allowance.name,
				value: combinedValues[allowance.name],
			}));

			combinedValues.role = role;
			combinedValues.childId = child_id;
			combinedValues.allowances = allowancesData;

			const response = await fetch(`${apiurl}/prejoinemployee/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(combinedValues),
			});

			const data = await response.json();
			if (response.ok) {
				setIsSuccess(true);
				message.success(data.message);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error(`Error: ${error.message}`);
		} finally {
			setLoading(false);
		}
	};

	if (loading) {
		return <Loader />;
	}

	if (isSuccess) {
		return <Success title={`${role} Added Successfully`} />;
	}

	return (
		<HeaderComponent>
			<div className="add-employee-cont">
				<Form
					className="add-emp-form"
					form={form}
					layout="vertical"
					initialValues={{ remember: true }}
					onFinish={onFinish}>
					<Card title="Basic Information" className="form-card">
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item
									name="userName"
									label="Name of the Employee"
									rules={[
										{
											required: true,
											message: "Please input the Employee's name",
										},
									]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item
									name="email"
									label="Email"
									rules={[
										{
											required: true,
											type: "email",
											message: "Please input a valid email",
										},
									]}>
									<Input type="email" />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									name="dateOfBirth"
									label="Date of Birth"
									rules={[
										{
											required: true,
											message: "Please select Date of Birth",
										},
									]}>
									<Input type="date" />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item
									name="phoneNumber"
									label="Phone Number"
									rules={[
										{ required: true, message: "Please input Phone Number" },
									]}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									name="type"
									label="Type"
									rules={[
										{
											required: true,
											message: "Please select Employee Type",
										},
									]}>
									<Select>
										<Option value="part_time">Part Time</Option>
										<Option value="full_time">Full Time</Option>
										<Option value="contract">Contract</Option>
										<Option value="other">Other</Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item
									name="gender"
									label="Gender"
									rules={[{ required: true, message: "Please select Gender" }]}>
									<Select>
										<Option value="male">Male</Option>
										<Option value="female">Female</Option>
										<Option value="other">Other</Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={24}>
								<Form.Item
									name="jobdescription"
									label="Job Description"
									rules={[
										{
											required: true,
											message: "Please input Job Description",
										},
									]}>
									<TextArea rows={4} />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={24}>
								<Form.Item
									name="kras"
									label="KRAs"
									rules={[{ required: true, message: "Please input KRAs" }]}>
									<TextArea rows={4} />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={24}>
								<Form.Item
									name="careerpath"
									label="Career Path"
									rules={[
										{ required: true, message: "Please input Career Path" },
									]}>
									<TextArea rows={4} />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item
									name="designation"
									label="Designation"
									rules={[
										{
											required: true,
											message: "Please select Designation",
										},
									]}>
									<Select>
										{designations.map((designation) => (
											<Option key={designation.id} value={designation.id}>
												{designation.name}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									name="department"
									label="Department"
									rules={[
										{
											required: true,
											message: "Please select Department",
										},
									]}>
									<Select>
										{departments.map((department) => (
											<Option key={department.id} value={department.id}>
												{department.name}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Form.Item className="form-buttons">
							<Button type="primary" htmlType="submit">
								Pre Join Employee
							</Button>
						</Form.Item>
					</Card>
				</Form>
			</div>
		</HeaderComponent>
	);
};

export default PrejoinEmployee;
