import React, { createContext, useEffect, useState } from "react";
import { message } from "antd";
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [token, setToken] = useState(null);

	const [sup,setSup] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();

	const success = (message) => {
		messageApi.open({
			type: "success",
			content: message,
		});
	};

	const error = (message) => {
		messageApi.open({
			type: "error",
			content: message,
		});
	};

	const warning = (message) => {
		messageApi.open({
			type: "warning",
			content: message,
		});
	};

	// const apiurl = "http://localhost:8000";
	const apiurl = process.env.REACT_APP_API_URL;
	// const apiurl = "http://192.168.146.119:8000";

	useEffect(() => {
		const storedToken = localStorage.getItem("gaorgsynctok");
		const storedSup = localStorage.getItem("is_sup");
		if (storedToken) {
			setToken(storedToken);
			setSup(storedSup);
		} else {
			const sessionToken = sessionStorage.getItem("gaorgsynctok");
			const storedSup = localStorage.getItem("is_sup");
			if (sessionToken) {
				setToken(sessionToken);
				setSup(storedSup);
			}
		}
		setIsLoading(false);
	}, []);

	const handleLogin = (token, supstatus) => {
		setToken(token);
		localStorage.setItem("gaorgsynctok", token);
		localStorage.setItem("is_sup", supstatus);
	};

	const handleSessionLogin = (token, supstatus) => {
		setToken(token);
		sessionStorage.setItem("gaorgsynctok", token);
		sessionStorage.setItem("is_sup", supstatus);
	};

	const handleLogout = () => {
		setToken(null);
		
		localStorage.removeItem("gaorgsynctok");
		sessionStorage.removeItem("gaorgsynctok");
		sessionStorage.removeItem("is_sup");
		localStorage.removeItem("is_sup");
	};

	if (isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<UserContext.Provider
			value={{
				handleLogin,
				handleSessionLogin,
				handleLogout,
				token,
				apiurl,
				success,
				error,
				warning,
				sup,
			}}>
			{contextHolder}
			{children}
		</UserContext.Provider>
	);
};