import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	Form,
	Modal,
	Input,
	message,
	Table,
	Popconfirm,
	Select,
} from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";

const PayrollAllowance = () => {
	const { apiurl, token } = useAuth();
	const [form] = Form.useForm();
	const [form1] = Form.useForm();
	const [allowances, setAllowances] = useState([]);
	const [prodAllowances, setProdAllowances] = useState([]);
	const [loading, setLoading] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [prodeditMode, setProdEditMode] = useState(false);
	const [createMode, setCreateMode] = useState(false);
	const [prodcreateMode, setProdCreateMode] = useState(false);
	const [currentAllowance, setCurrentAllowance] = useState(null);

	useEffect(() => {
		fetchAllowances();
		fetchProdAllowances();
	}, []);

	const fetchProdAllowances = async () => {
		const child_id = sessionStorage.getItem("selectedChildId");
		try {
			const response = await fetch(
				`${apiurl}/production/allowances/?child_id=${child_id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				setProdAllowances(data.data);
			} else {
				message.error("Failed to fetch production allowances.");
			}
		} catch (error) {
			message.error(`Error: ${error.message}`);
		}
	};

	const fetchAllowances = async () => {
		const child_id = sessionStorage.getItem("selectedChildId");
		try {
			const response = await fetch(
				`${apiurl}/allowances/?child_id=${child_id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				setAllowances(data.data);
			} else {
				message.error("Failed to fetch allowances.");
			}
		} catch (error) {
			message.error(`Error: ${error.message}`);
		}
	};

	const handleCreate = async (values) => {
		const child_id = sessionStorage.getItem("selectedChildId");
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/allowances/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					...values,
					child_id: child_id,
				}),
			});
			if (response.ok) {
				message.success("Allowance created successfully.");
				setCreateMode(false);
				fetchAllowances();
			} else {
				message.error("Failed to create allowance.");
			}
		} catch (error) {
			message.error(error.message || "Error creating allowance.");
		} finally {
			setLoading(false);
		}
	};

	const handleProdCreate = async (values) => {
		const child_id = sessionStorage.getItem("selectedChildId");
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/production/allowances/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					...values,
					child_id: child_id,
				}),
			});
			if (response.ok) {
				message.success("Allowance created successfully.");
				setProdCreateMode(false);
				fetchProdAllowances();
			} else {
				message.error("Failed to create allowance.");
			}
		} catch (error) {
			message.error(error.message || "Error creating allowance.");
		} finally {
			setLoading(false);
		}
	};

	const handleProdUpdate = async (values) => {
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/production/allowances/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					...values,
					all_id: currentAllowance.id,
				}),
			});
			if (response.ok) {
				message.success("Allowance updated successfully.");
				setProdCreateMode(false);
				setCurrentAllowance(null);
				fetchProdAllowances();
			} else {
				message.error("Failed to update allowance.");
			}
		} catch (error) {
			message.error(error.message || "Error updating allowance.");
		} finally {
			setLoading(false);
		}
	};

	const handleUpdate = async (values) => {
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/allowances/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					...values,
					all_id: currentAllowance.id,
				}),
			});
			if (response.ok) {
				message.success("Allowance updated successfully.");
				setEditMode(false);
				setCurrentAllowance(null);
				fetchAllowances();
			} else {
				message.error("Failed to update allowance.");
			}
		} catch (error) {
			message.error(error.message || "Error updating allowance.");
		} finally {
			setLoading(false);
		}
	};

	const handleProdDelete = async (id) => {
		try {
			const response = await fetch(`${apiurl}/production/allowances/`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ all_id: id }),
			});
			if (response.ok) {
				message.success("Allowance deleted successfully.");
				fetchProdAllowances();
			} else {
				message.error("Failed to delete allowance.");
			}
		} catch (error) {
			message.error(error.message || "Error deleting allowance.");
		}
	};

	const handleDelete = async (id) => {
		try {
			const response = await fetch(`${apiurl}/allowances/`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ all_id: id }),
			});
			if (response.ok) {
				message.success("Allowance deleted successfully.");
				fetchAllowances();
			} else {
				message.error("Failed to delete allowance.");
			}
		} catch (error) {
			message.error(error.message || "Error deleting allowance.");
		}
	};

	const prodcolumns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
		},
		{
			title: "Min Amount(Per Day)",
			dataIndex: "min_value",
			key: "min_value",
		},
		{
			title: "Max Amount(Per Day)",
			dataIndex: "max_value",
			key: "max_value",
		},
		{
			title: "Actions",
			key: "actions",
			render: (_, record) => (
				<div>
					<Button
						icon={<EditOutlined />}
						onClick={() => {
							setCurrentAllowance(record);
							form1.setFieldsValue({
								name: record.name,
								min_value: record.min_value,
								max_value: record.max_value,
							});
							setProdEditMode(true);
						}}
						style={{ marginRight: 8 }}
					/>
					<Popconfirm
						title="Are you sure you want to delete this allowance?"
						onConfirm={() => handleProdDelete(record.id)}
						okText="Yes"
						cancelText="No">
						<Button danger>Delete</Button>
					</Popconfirm>
				</div>
			),
		},
	];

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Amount",
			dataIndex: "min_value",
			key: "min_value",
		},
		{
			title: "Actions",
			key: "actions",
			render: (_, record) => (
				<div>
					<Button
						icon={<EditOutlined />}
						onClick={() => {
							setCurrentAllowance(record);
							form.setFieldsValue({
								name: record.name,
								amount: record.min_value,
							});
							setEditMode(true);
						}}
						style={{ marginRight: 8 }}
					/>
					<Popconfirm
						title="Are you sure you want to delete this allowance?"
						onConfirm={() => handleDelete(record.id)}
						okText="Yes"
						cancelText="No">
						<Button danger>Delete</Button>
					</Popconfirm>
				</div>
			),
		},
	];

	return (
		<div className="policy-main">
			<Card
				className="policy"
				bordered={false}
				title="White-Collar Allowances"
				extra={
					<Button
						icon={<PlusOutlined />}
						onClick={() => setCreateMode(true)}
						disabled={createMode}>
						Add Allowance
					</Button>
				}>
				<Table
					dataSource={allowances}
					columns={columns}
					rowKey="id"
					loading={loading}
				/>
			</Card>
			<Card
				className="policy"
				bordered={false}
				title="Blue-Collar Allowances"
				extra={
					<Button
						icon={<PlusOutlined />}
						onClick={() => setProdCreateMode(true)}
						disabled={prodcreateMode}>
						Add Allowance
					</Button>
				}>
				<Table
					dataSource={prodAllowances}
					columns={prodcolumns}
					rowKey="id"
					loading={loading}
				/>
			</Card>

			<Modal
				open={createMode}
				destroyOnClose
				title="Create Allowance"
				onCancel={() => setCreateMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setCreateMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form form={form} layout="vertical" onFinish={handleCreate}>
					<Form.Item
						name="name"
						label="Name"
						rules={[
							{ required: true, message: "Please enter allowance name." },
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="amount"
						label="Amount"
						rules={[
							{ required: true, message: "Please enter allowance amount." },
						]}>
						<Input type="number" />
					</Form.Item>
				</Form>
			</Modal>

			<Modal
				open={prodcreateMode}
				destroyOnClose
				title="Create Allowance"
				onCancel={() => setProdCreateMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setProdCreateMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form1.submit()}>
						Submit
					</Button>,
				]}>
				<Form form={form1} layout="vertical" onFinish={handleProdCreate}>
					<Form.Item
						name="name"
						label="Name"
						rules={[
							{ required: true, message: "Please enter allowance name." },
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="type"
						label="Type"
						rules={[
							{ required: true, message: "Please enter allowance name." },
						]}>
						<Select>
							<Select.Option value="allowance">Allowance</Select.Option>
							<Select.Option value="deduction">Deduction</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						name="min_value"
						label="Min Amount(Per Day)"
						rules={[
							{
								required: true,
								message: "Please enter minimum allowance amount per day.",
							},
						]}>
						<Input type="number" />
					</Form.Item>
					<Form.Item
						name="max_value"
						label="Max Amount(Per Day)"
						rules={[
							{
								required: true,
								message: "Please enter maximum allowance amount per day.",
							},
						]}>
						<Input type="number" />
					</Form.Item>
				</Form>
			</Modal>

			<Modal
				open={editMode}
				destroyOnClose
				title="Edit Allowance"
				onCancel={() => setEditMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setEditMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form}
					layout="vertical"
					onFinish={handleUpdate}
					initialValues={{
						name: currentAllowance?.name,
						amount: currentAllowance?.min_value,
					}}>
					<Form.Item
						name="name"
						label="Name"
						rules={[
							{ required: true, message: "Please enter allowance name." },
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="amount"
						label="Amount"
						rules={[
							{ required: true, message: "Please enter allowance amount." },
						]}>
						<Input type="number" />
					</Form.Item>
				</Form>
			</Modal>

			<Modal
				open={prodeditMode}
				destroyOnClose
				title="Edit Allowance"
				onCancel={() => setProdEditMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setProdEditMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form1.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form1}
					layout="vertical"
					onFinish={handleProdUpdate}
					initialValues={{
						name: currentAllowance?.name,
						type: currentAllowance?.type,
						min_value: currentAllowance?.min_value,
						max_value: currentAllowance?.min_value,
					}}>
					<Form.Item
						name="name"
						label="Name"
						rules={[
							{ required: true, message: "Please enter allowance name." },
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="type"
						label="Type"
						rules={[
							{ required: true, message: "Please enter allowance name." },
						]}>
						<Select>
							<Select.Option value="allowance">Allowance</Select.Option>
							<Select.Option value="deduction">Deduction</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						name="min_value"
						label="Min Amount(Per Day)"
						rules={[
							{
								required: true,
								message: "Please enter minimum allowance amount per day.",
							},
						]}>
						<Input type="number" />
					</Form.Item>
					<Form.Item
						name="max_value"
						label="Max Amount(Per Day)"
						rules={[
							{
								required: true,
								message: "Please enter maximum allowance amount per day.",
							},
						]}>
						<Input type="number" />
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default PayrollAllowance;
