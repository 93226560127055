import React from "react";
import HeaderComponent from "../Header/Header";
import LeavePolicy from "./LeavePolicy";
import PayrollPolicy from "./PayrollPolicy";
import AttendancePolicy from "./AttendancePolicy";
import AttendanceRequestPolicy from "./AttendanceRequestPolicy";
import { LeftCircleOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import "./Policy.css"; 
import PayrollAllowances from "./PayrollAllowances";
import PfSlabsComponent from "./PfSlabs";

const { TabPane } = Tabs;

const Policy = () => {
	const navigate = useNavigate();

	return (
		<HeaderComponent>
			<div className="go-back">
				<Button type="text" onClick={() => navigate(-1)}>
					<LeftCircleOutlined />
					Go back
				</Button>
			</div>
			<div className="policy-container">
				<Tabs defaultActiveKey="1">
					<TabPane tab="Leave Policy" key="1">
						<LeavePolicy />
					</TabPane>
					<TabPane tab="Payslip Layout" key="2">
						<PayrollPolicy />
					</TabPane>
					<TabPane tab="Payroll" key="3">
						<PayrollAllowances />
					</TabPane>
					{/* <TabPane tab="PF Slabs" key="4">
						<PfSlabsComponent />
					</TabPane> */}
					<TabPane tab="Attendance Policy" key="5">
						<AttendancePolicy />
					</TabPane>
					<TabPane tab="Attendance Request Policy" key="6">
						<AttendanceRequestPolicy />
					</TabPane>
				</Tabs>
			</div>
		</HeaderComponent>
	);
};

export default Policy;
