import React, { useState, useEffect } from "react";
import { useAuth } from "../../utils/useAuth";
import { message, DatePicker, Button, Table, Checkbox } from "antd";
import dayjs from "dayjs";

const AddAttendances = ({ onSuccess }) => {
	const { apiurl, token } = useAuth();
	const [empData, setEmpData] = useState([]);
	const [selectedDate, setSelectedDate] = useState(dayjs());
	const [absentDays, setAbsentDays] = useState({});

	useEffect(() => {
		fetchEmployeeDetails();
	}, []);

	const fetchEmployeeDetails = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchemployees/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});

			const data = await response.json();

			if (response.ok) {
				setEmpData(data.data);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("An error occurred while fetching employee data.");
		}
	};

	const handleDateChange = (date) => {
		setSelectedDate(date);
	};

	const handleCheckboxChange = (empId, day) => {
		setAbsentDays((prev) => {
			const updated = { ...prev };
			if (!updated[empId]) updated[empId] = new Set();

			if (updated[empId].has(day)) {
				updated[empId].delete(day);
			} else {
				updated[empId].add(day);
			}
			return { ...updated };
		});
	};

	const handleSubmit = async () => {
		const month = selectedDate.month() + 1;
		const year = selectedDate.year();

		const attendanceData = Object.entries(absentDays).map(([empId, days]) => ({
			empId,
			month,
			year,
			absentDays: Array.from(days),
		}));

		try {
			const response = await fetch(`${apiurl}/addattendancesingle/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ attendances: attendanceData }),
			});

			const data = await response.json();
			if (response.ok) {
				onSuccess();
				message.success("Attendance submitted successfully!");
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("An error occurred while submitting attendance.");
		}
	};

	const daysInMonth = selectedDate ? selectedDate.daysInMonth() : 1;

	const columns = [
		{
			title: "Employee Name",
			dataIndex: "userName",
			key: "userName",
		},
		{
			title: "Employee Id",
			dataIndex: "employeeid",
			key: "employeeid",
		},
		...Array.from({ length: daysInMonth }, (_, i) => ({
			title: i + 1,
			dataIndex: i + 1,
			key: i + 1,
			render: (_, record) => (
				<Checkbox
					checked={absentDays[record.id]?.has(i + 1)}
					onChange={() => handleCheckboxChange(record.id, i + 1)}
				/>
			),
		})),
	];

	return (
		<div>
			<h2>Mark Employee Absences</h2>
			<DatePicker
				picker="month"
				onChange={handleDateChange}
				value={selectedDate}
			/>
			<Table
				dataSource={empData}
				columns={columns}
				rowKey="id"
				pagination={false}
			/>
			<Button type="primary" onClick={handleSubmit} style={{ marginTop: 16 }}>
				Submit Attendance
			</Button>
		</div>
	);
};

export default AddAttendances;
